import { accessibility } from '../constants/general'
import routeLink from '../constants/routeLink'
const routeConstants = {
  Door: {
    path: routeLink.Door,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Door/Door',
  },
  Engineering: {
    path: routeLink.Engineering,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Engineering/index',
  },
  ExtraServices: {
    path: routeLink.ExtraServices,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Engineering/ExtraServices/ExtraServices',
  },
  MoreListing: {
    path: `${routeLink.MoreServices}/:deptId`,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'MoreListing/MoreListing',
  },
  MaintenanceInprogress: {
    path: routeLink.MaintenanceInprogress,
    exact: true,
    isAuthenticated: false,
    routeComponentParent: 'pages',
    routeComponentPath: 'Maintenance/Maintenance',
    type: accessibility.PUBLIC,
  },
  Hotel: {
    path: ['/', routeLink.Hotel, `${routeLink.Hotel}/:hotelslugname`],
    exact: true,
    isAuthenticated: false,
    routeComponentParent: 'pages',
    routeComponentPath: 'Hotel/Hotel',
  },
  LanguageScreen: {
    path: routeLink.LanguageScreen,
    exact: true,
    isAuthenticated: false,
    routeComponentParent: 'pages',
    routeComponentPath: 'LanguageScreen/LanguageScreen',
  },
  ChangeLanguageScreen: {
    path: routeLink.ChangeLanguageScreen,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'LanguageScreen/LanguageScreen',
  },
  BookingReference: {
    path: routeLink.BookingReference,
    exact: true,
    isAuthenticated: false,
    routeComponentParent: 'pages',
    routeComponentPath: 'BookingReference/BookingReference',
  },
  PreChecking: {
    path: routeLink.PreChecking,
    exact: true,
    isAuthenticated: false,
    routeComponentParent: 'pages',
    routeComponentPath: 'PreChecking/PreChecking',
  },
  ConfirmationScreen: {
    path: routeLink.ConfirmationScreen,
    exact: true,
    isAuthenticated: false,
    routeComponentParent: 'pages',
    routeComponentPath: 'ConfirmationScreen/ConfirmationScreen',
    type: accessibility.CONFIRMATION_CHECKPOINT,
  },
  Home: {
    path: routeLink.Home,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Home/Home',
  },
  FrontDesk: {
    path: routeLink.FrontDesk,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'FrontDesk/FrontDesk',
  },
  FoodAndBeverage: {
    path: routeLink.FoodAndBeverage,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'FoodandBeverage/FoodandBeverage',
  },

  Partners: {
    path: routeLink.Partners,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Partners/Partners',
  },

  MyProfile: {
    path: routeLink.MyProfile,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'MyProfile/MyProfile',
  },
  HouseKeeping: {
    path: routeLink.HouseKeeping,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'HouseKeeping/HouseKeeping',
  },

  DoctoronCall: {
    path: routeLink.DoctoronCall,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'FrontDesk/DoctoronCall/DoctoronCall',
  },
  ViewBill: {
    path: routeLink.ViewBill,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'FrontDesk/ViewBill/ViewBill',
  },
  Chat: {
    path: routeLink.Chat,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Chat/Chat',
  },
  Maintenance: {
    path: routeLink.Maintenance,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'HouseKeeping/Maintanance/Maintanance',
  },
  Replacement: {
    path: routeLink.Replacement,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'HouseKeeping/Replacement/Replacement',
  },
  RoomCleaning: {
    path: routeLink.RoomCleaning,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'HouseKeeping/RoomCleaning/RoomCleaning',
  },
  ClearTray: {
    path: routeLink.ClearTray,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'HouseKeeping/ClearTray/ClearTray',
  },
  Complementary: {
    path: routeLink.Complementary,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'HouseKeeping/Complementary/Complementary',
  },
  Chargeable: {
    path: routeLink.Chargeable,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'HouseKeeping/Chargeable/Chargeable',
  },
  Laundry: {
    path: routeLink.Laundry,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'HouseKeeping/Laundry/Laundry',
  },
  HotelInfo: {
    path: routeLink.HotelInfo,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'HotelInfo/HotelInfo',
  },
  ServiceRating: {
    path: [routeLink.ServiceRating, `${routeLink.ServiceRating}/:requestId`],
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'ServiceRating/ServiceRating',
  },
  OverallFeedback: {
    path: [routeLink.OverallFeedback],
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'HotelFeedback/HotelFeedback',
  },
  TermsandConditions: {
    path: routeLink.TermsandConditions,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'TermsandConditions/TermsandConditions',
  },
  PrivacyPolicy: {
    path: routeLink.PrivacyPolicy,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'PrivacyPolicy/PrivacyPolicy',
  },
  SuccessScreen: {
    path: routeLink.SuccessScreen,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'SuccessScreen/SuccessScreen',
    type: accessibility.DUPLICATE_ROUTE,
  },
  SelectoptionTemplates: {
    path: routeLink.SelectoptionTemplates,
    exact: true,
    isAuthenticated: false,
    routeComponentParent: 'pages',
    routeComponentPath: 'Templates/SelectoptionTemplates',
  },
  TimepickerTemplate: {
    path: routeLink.TimepickerTemplate,
    exact: true,
    isAuthenticated: false,
    routeComponentParent: 'pages',
    routeComponentPath: 'Templates/TimepickerTemplate',
  },
  DatepickerTemplate: {
    path: routeLink.DatepickerTemplate,
    exact: true,
    isAuthenticated: false,
    routeComponentParent: 'pages',
    routeComponentPath: 'Templates/DatepickerTemplate',
  },
  DateTimepickerTemplate: {
    path: routeLink.DateTimepickerTemplate,
    exact: true,
    isAuthenticated: false,
    routeComponentParent: 'pages',
    routeComponentPath: 'Templates/DateTimepickerTemplate',
  },
  ChangeUpgradeRoom: {
    path: routeLink.ChangeUpgradeRoom,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'FrontDesk/ChangeUpgradeRoom/ChangeUpgradeRoom',
  },
  ExtraBed: {
    path: routeLink.ExtraBed,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'FrontDesk/ExtraBed/ExtraBed',
  },
  ExtendStay: {
    path: routeLink.ExtendStay,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'FrontDesk/ExtendStay/ExtendStay',
  },
  WakeUpCall: {
    path: routeLink.WakeupCall,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'FrontDesk/WakeupCall/WakeupCall',
  },
  AirportDropOff: {
    path: routeLink.AirportDropOff,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'FrontDesk/AirportDropOff/AirportDropOff',
  },
  CheckoutAndRequestBill: {
    path: routeLink.CheckoutAndRequestBill,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath:
      'FrontDesk/CheckoutAndRequestBill/CheckoutAndRequestBill',
  },
  CheckoutAndRequestBillSuccess: {
    path: routeLink.CheckoutAndRequestBillSuccess,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath:
      'FrontDesk/CheckoutAndRequestBill/CheckoutAndRequestBillSuccess',
  },
  RoomServiceCart: {
    path: routeLink.RoomServiceCart,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'FoodandBeverage/RoomService/RoomServiceCart',
  },
  HouseKeepingCart: {
    path: routeLink.HouseKeepingCart,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Medical/MedicalService/HouseKeepingCart',
  },
  MedicalServiceCart: {
    path: routeLink.MedicalServiceCart,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Medical/MedicalService/MedicalServiceCart',
  },
  RoomServiceMenu: {
    path: routeLink.RoomServiceMenu,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'FoodandBeverage/RoomService/RoomServiceMenu',
  },
  DoctorOnCallService: {
    path: routeLink.DoctorOnCallService,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'DoctorOnCall/DoctorOnCallService/DoctorOnCallService',
  },
  CusineList: {
    path: routeLink.CuisineList,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'FoodandBeverage/Restaurant/CuisineList',
  },
  RestaurantLists: {
    path: routeLink.RestaurantLists,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'FoodandBeverage/Restaurant/RestaurantLists',
  },
  Reservation: {
    path: routeLink.Reservation,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'FoodandBeverage/Restaurant/Reservation',
  },
  Concierge: {
    path: routeLink.Concierge,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Concierge/Concierge',
  },
  BookTaxi: {
    path: routeLink.BookTaxi,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Concierge/BookTaxi/BookTaxi',
  },
  CarRental: {
    path: routeLink.CarRental,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Concierge/CarRental/CarRental',
  },
  GetMyCar: {
    path: routeLink.GetMyCar,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Concierge/GetMyCar/GetMyCar',
  },
  HotelShuttle: {
    path: routeLink.HotelShuttle,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Concierge/HotelShuttle/HotelShuttle',
  },
  TravelDesk: {
    path: routeLink.TravelDesk,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Concierge/TravelDesk/TravelDesk',
  },
  Promotions: {
    path: routeLink.Promotions,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Promotions/Promotions',
  },
  HotelGuidelines: {
    path: routeLink.HotelGuidelines,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'HotelGuidelines/HotelGuidelines',
  },
  PromotionDetails: {
    path: routeLink.PromotionDetails,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Promotions/Promotiondetails',
  },
  SpaWellness: {
    path: routeLink.SpaWellness,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'SpaWellness/SpaWellness',
  },
  SpaDetails: {
    path: routeLink.SpaDetails,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'SpaWellness/SpawellnessDetails/SpaDetails',
  },
  SaloonDetails: {
    path: routeLink.SaloonDetails,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'SpaWellness/SpawellnessDetails/SaloonDetails',
  },
  GymDetails: {
    path: routeLink.GymDetails,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'SpaWellness/SpawellnessDetails/GymDetails',
  },
  SpaBooking: {
    path: routeLink.SpaBooking,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'SpaWellness/SpawellnessDetails/SpaBooking',
  },
  SaloonBooking: {
    path: routeLink.SaloonBooking,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'SpaWellness/SpawellnessDetails/SaloonBooking',
  },
  GymBooking: {
    path: routeLink.GymBooking,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'SpaWellness/SpawellnessDetails/GymBooking',
  },
  Notifications: {
    path: routeLink.Notifications,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Notifications/Notifications',
  },
  LostAndFound: {
    path: routeLink.LostAndFound,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'LostAndFound/LostAndFound',
  },
  FoundItems: {
    path: routeLink.FoundItems,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'LostAndFound/FoundItems/FoundItems',
  },
  LostItems: {
    path: routeLink.LostItems,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'LostAndFound/LostItems/LostItems',
  },
  AroundMe: {
    path: routeLink.AroundMe,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'AroundMe/AroundMe',
  },
  Events: {
    path: routeLink.Events,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Events/Events',
  },
  EventsList: {
    path: routeLink.EventsList,
    exact: true,
    isAuthenticated: true,
    routeComponentParent: 'pages',
    routeComponentPath: 'Events/EventsList',
  },
  IntermediateScreen: {
    path: routeLink.IntermediateScreen,
    exact: true,
    isAuthenticated: false,
    routeComponentParent: 'pages',
    routeComponentPath: 'IntermediateScreen',
    type: accessibility.PUBLIC,
  },
  OfflinePage: {
    path: routeLink.OfflinePage,
    exact: true,
    isAuthenticated: false,
    routeComponentParent: 'pages',
    routeComponentPath: 'OfflinePage',
    type: accessibility.PUBLIC,
  },
  WrongPlace: {
    path: routeLink.WrongPlace,
    exact: true,
    isAuthenticated: false,
    routeComponentParent: 'pages',
    routeComponentPath: 'WrongPlace/WrongPlace',
  },
  Error404: {
    path: routeLink.NonAuthPageNotFound,
    exact: true,
    isAuthenticated: false,
    routeComponentParent: 'pages',
    routeComponentPath: 'NotFound/NotFound',
  },
}

export default routeConstants
