const DuplicateRequestImg =
  require('../assets/images/DuplicateRequest.svg').default
const eventsImg = require('../assets/images/events.png').default
const fCallImg = require('../assets/images/fCall.svg').default
const fHomeImg = require('../assets/images/fHome.svg').default
const fHotelImg = require('../assets/images/fHotel.svg').default
const foodandbeverageImg =
  require('../assets/images/foodandbeverage.png').default
const frontdeskImg = require('../assets/images/frontdesk.png').default
const hamburger_bellImg = require('../assets/images/hamburger_bell.svg').default
const hamburgericonImg = require('../assets/images/hamburgericon.svg').default
const housekeepingImg = require('../assets/images/housekeeping.png').default
const InplassLogoRedImg =
  require('../assets/images/InPlass-Logo-Red.svg').default
const landingfigImg = require('../assets/images/landingfig.png').default
const medicalDoctorImg = require('../assets/images/medicalDoctor.png').default
const languagebannerImg = require('../assets/images/languagebanner.svg').default
const LanguageIconImg = require('../assets/images/LanguageIcon.svg').default
const locationImg = require('../assets/images/location.svg').default
const logoutImg = require('../assets/images/logout.svg').default
const mapImg = require('../assets/images/map.png').default
const moviebookingImg = require('../assets/images/moviebooking.png').default
const myDoorImg = require('../assets/images/mydoor.jpeg').default
const msgiconImg = require('../assets/images/msgicon.svg').default
const myhoteliconImg = require('../assets/images/myhotelicon.svg').default
const notificationImg = require('../assets/images/notificationimg.png').default
const powerdlogoImg = require('../assets/images/powerdlogo.svg').default
const powerdtextImg = require('../assets/images/powerdtext.svg').default
const privacypolicyiconImg =
  require('../assets/images/privacypolicyicon.svg').default
const profileImg = require('../assets/images/profile.png').default
const profileiconImg = require('../assets/images/profileicon.svg').default
const promotionsImg = require('../assets/images/promotions.png').default
const hotelGuidelineImg =
  require('../assets/images/hotel-guideline.jpg').default
const qrImg = require('../assets/images/qr.png').default
const RestaurantPlaceholderImg =
  require('../assets/images/RestaurantPlaceholder.svg').default
const spawellnessImg = require('../assets/images/spawellness.png').default
const successImg = require('../assets/images/success.svg').default
const sunsetsImg = require('../assets/images/sunsets.png').default
const termsbannerImg = require('../assets/images/termsbanner.svg').default
const termscondtionsiconImg =
  require('../assets/images/termscondtionsicon.svg').default
const timeImg = require('../assets/images/time.svg').default
const welcomeImg = require('../assets/images/welcome.svg').default
const burjalarabImg =
  require('../assets/images/aroundme/burjalarab.png').default
const burjkhalifaImg =
  require('../assets/images/aroundme/burjkhalifa.png').default
const dubaifountainImg =
  require('../assets/images/aroundme/dubaifountain.png').default
const dubaimallImg = require('../assets/images/aroundme/dubaimall.png').default
const dubaioperaImg =
  require('../assets/images/aroundme/dubaiopera.png').default
const jumeirahbeachImg =
  require('../assets/images/aroundme/jumeirahbeach.png').default
const skydubaiImg = require('../assets/images/aroundme/skydubai.png').default
const aroundmebannerImg =
  require('../assets/images/aroundme/aroundmebanner.png').default
const alnoorislandImg =
  require('../assets/images/aroundme/alnoorisland.png').default
const booktaxibannerImg =
  require('../assets/images/concierge/booktaxibanner.png').default
const carrentalImg = require('../assets/images/concierge/carrental.png').default
const carrentalbannerImg =
  require('../assets/images/concierge/carrentalbanner.png').default
const conciergebannerImg =
  require('../assets/images/concierge/conciergebanner.png').default
const getmycarImg = require('../assets/images/concierge/getmycar.png').default
const getmycarbannerImg =
  require('../assets/images/concierge/getmycarbanner.png').default
const hotelshuttleImg =
  require('../assets/images/concierge/hotelshuttle.png').default
const hotelshuttlebannerImg =
  require('../assets/images/concierge/hotelshuttlebanner.png').default
const traveldeskImg =
  require('../assets/images/concierge/traveldesk.png').default
const traveldeskbannerImg =
  require('../assets/images/concierge/traveldeskbanner.png').default
const traveldeskfigImg =
  require('../assets/images/concierge/traveldeskfig.png').default
const conferenceImg = require('../assets/images/events/conference.png').default
const cultureventsImg =
  require('../assets/images/events/culturevents.png').default
const eventsbannerImg =
  require('../assets/images/events/eventsbanner.png').default
const exhibitionsImg =
  require('../assets/images/events/exhibitions.png').default
const musicnightbannerImg =
  require('../assets/images/events/musicnightbanner.png').default
const musicnightsImg =
  require('../assets/images/events/musicnights.png').default
const cusineImg = require('../assets/images/foodandbeverage/cusine.png').default
const foodandbeveragebannerImg =
  require('../assets/images/foodandbeverage/foodandbeveragebanner.png').default
const menuitemImg =
  require('../assets/images/foodandbeverage/menuitem.png').default
const dishPlaceHolderImg =
  require('../assets/images/foodandbeverage/dish.png').default

const restaurantImg =
  require('../assets/images/foodandbeverage/restaurant.png').default
const restaurantbannerImg =
  require('../assets/images/foodandbeverage/restaurantbanner.png').default
const roomserviceImg =
  require('../assets/images/foodandbeverage/roomservice.png').default
const roomservicebannerImg =
  require('../assets/images/foodandbeverage/roomservicebanner.png').default
const changeroomImg =
  require('../assets/images/frontdesk/changeroom.png').default
const changeroombannerImg =
  require('../assets/images/frontdesk/changeroombanner.png').default
const checkoutrequestbillbannerImg =
  require('../assets/images/frontdesk/checkoutrequestbillbanner.png').default
const doctoroncallImg =
  require('../assets/images/frontdesk/doctoroncall.png').default
const doctoroncallbannerImg =
  require('../assets/images/frontdesk/doctoroncallbanner.png').default
const droptaxiImg = require('../assets/images/frontdesk/droptaxi.png').default
const extrabedImg = require('../assets/images/frontdesk/extrabed.png').default
const extrabedbannerImg =
  require('../assets/images/frontdesk/extrabedbanner.png').default
const lostandfoundImg =
  require('../assets/images/frontdesk/lostandfoundbanner.png').default
const lostandfoundbannerImg =
  require('../assets/images/frontdesk/lostandfoundbanner.png').default
const founditemImg =
  require('../assets/images/frontdesk/founditemimage.png').default
const lostitemImg =
  require('../assets/images/frontdesk/lostitemimg.png').default
const frontdeskbannerImg =
  require('../assets/images/frontdesk/frontdeskbanner.png').default
const requestbillImg =
  require('../assets/images/frontdesk/requestbill.png').default
const viewbillImg = require('../assets/images/frontdesk/viewbill.png').default
const viewmybillbannerImg =
  require('../assets/images/frontdesk/viewmybillbanner.png').default
const wakeupcallImg =
  require('../assets/images/frontdesk/wakeupcall.png').default
const wakeupcallbannerImg =
  require('../assets/images/frontdesk/wakeupcallbanner.png').default
const airdropoffbannerImg =
  require('../assets/images/frontdesk/airdropoffbanner.png').default
const bedlinenImg =
  require('../assets/images/housekeeping/bedlinen.png').default
const cleartrayImg =
  require('../assets/images/housekeeping/cleartray.png').default
const complementaryImg = require('../assets/images/complementary.jpg').default
const chargeablesImg = require('../assets/images/chargeables.jpeg').default
const cleartraybannerImg =
  require('../assets/images/housekeeping/cleartraybanner.png').default
const electricImg =
  require('../assets/images/housekeeping/electric.png').default
const housekeepingbannerImg =
  require('../assets/images/housekeeping/housekeepingbanner.png').default
const laundryImg = require('../assets/images/housekeeping/laundry.png').default
const lightImg = require('../assets/images/housekeeping/light.png').default
const maintananceImg =
  require('../assets/images/housekeeping/maintanance.png').default
const maintanancebannerImg =
  require('../assets/images/housekeeping/maintanancebanner.png').default
const minibarImg = require('../assets/images/housekeeping/minibar.png').default
const picklaundrybannerImg =
  require('../assets/images/housekeeping/picklaundrybanner.png').default
const pillowsImg = require('../assets/images/housekeeping/pillows.png').default
const refrgeratorImg =
  require('../assets/images/housekeeping/refrgerator.png').default
const replacementbannerImg =
  require('../assets/images/housekeeping/replacementbanner.png').default
const replacementsImg =
  require('../assets/images/housekeeping/replacements.png').default
const roomcleaningImg =
  require('../assets/images/housekeeping/roomcleaning.png').default
const roomcleaningbannerImg =
  require('../assets/images/housekeeping/roomcleaningbanner.png').default
const telivisionImg =
  require('../assets/images/housekeeping/telivision.png').default
const toiletriesImg =
  require('../assets/images/housekeeping/toiletries.png').default
const waterleakageImg =
  require('../assets/images/housekeeping/waterleakage.png').default
const airconditionerImg =
  require('../assets/images/housekeeping/airconditioner.png').default
const arrowRight = require('../assets/images/arrow-right.svg').default
const aroundMe = require('../assets/images/aroundme.png').default
const booktaxi = require('../assets/images/booktaxi.png').default
const concierge = require('../assets/images/concierge.png').default
const bell = require('../assets/images/bell.svg').default
const backArrow = require('../assets/images/backarrow.svg').default
const banner = require('../assets/images/banner.png').default
const CuisinePlaceholder =
  require('../assets/images/CuisinePlaceholder.svg').default
const promotionBanner = getImage('promotions/promotionsbanner.png')
const promotionBG1 = getImage('promotions/promotionbg1.png')

const billfig = getImage('billfig.svg')
const containerImage = getImage('doconcallfig.svg')
const cartfig = getImage('cartfig.png')
const doctorPatient = getImage('doctorpatient.png')
const cartImage = getImage('cart.png')
const bookingref = getImage('bookingref.svg')
const spaBanner = getImage('spawellness/spabanner.png')
const spaImg = getImage('spawellness/spa.png')
const foodandbeverage_cusine = getImage('foodandbeverage/cusine.png')
const check_out_img = getImage('check-out-img.svg')
const spawellnessbanner = getImage('spawellness/spawellnessbanner.png')
const spawellness_salon = getImage('spawellness/salon.png')
const sw_saloonbanner = getImage('spawellness/saloonbanner.png')
const sw_gym = getImage('spawellness/gym.png')
const sw_gymbanner = getImage('spawellness/gymbanner.png')
const pdficon = require('../assets/images/pdfimage.png').default
const dummypdf = require('../assets/dummypdf.pdf').default
const maintenanceImg = require('../assets/images/maintenance.svg').default
const ratingImg = require('../assets/images/rating.svg').default
const successtick = require('../assets/images/tick.png').default
const failedcross = require('../assets/images/cross.png').default
const serviceRatingHeaderImg = getImage('serviceRatingHeaderImg.svg')
const overallfeedbackImg = getImage('overallfeedback.svg')
const overallfeedbackWhiteImg = getImage('overallfeedbackWhite.svg')
const overallfeedbackBannerImg = getImage('overallfeedbackBanner.svg')
const webKeyProviderImg =
  require('../assets/images/FLEXIPASS_SALTO_.svg').default
const doorImg = require('../assets/images/door1.svg').default
const preCheckinConfirmation = require('../assets/images/preCheckinConfirmation.png').default


export {
  overallfeedbackWhiteImg,
  airconditionerImg,
  airdropoffbannerImg,
  alnoorislandImg,
  aroundMe,
  aroundmebannerImg,
  arrowRight,
  backArrow,
  banner,
  bedlinenImg,
  bell,
  billfig,
  bookingref,
  booktaxi,
  booktaxibannerImg,
  burjalarabImg,
  burjkhalifaImg,
  carrentalbannerImg,
  carrentalImg,
  cartfig,
  changeroombannerImg,
  changeroomImg,
  check_out_img,
  checkoutrequestbillbannerImg,
  cleartraybannerImg,
  cleartrayImg,
  complementaryImg,
  chargeablesImg,
  concierge,
  conciergebannerImg,
  conferenceImg,
  containerImage,
  CuisinePlaceholder,
  cultureventsImg,
  cusineImg,
  doctoroncallbannerImg,
  doctoroncallImg,
  droptaxiImg,
  dubaifountainImg,
  dubaimallImg,
  dubaioperaImg,
  dummypdf,
  DuplicateRequestImg,
  electricImg,
  eventsbannerImg,
  eventsImg,
  exhibitionsImg,
  extrabedbannerImg,
  extrabedImg,
  failedcross,
  fCallImg,
  fHomeImg,
  fHotelImg,
  foodandbeverage_cusine,
  foodandbeveragebannerImg,
  foodandbeverageImg,
  founditemImg,
  frontdeskbannerImg,
  frontdeskImg,
  getmycarbannerImg,
  getmycarImg,
  hamburger_bellImg,
  hamburgericonImg,
  hotelGuidelineImg,
  hotelshuttlebannerImg,
  hotelshuttleImg,
  housekeepingbannerImg,
  housekeepingImg,
  InplassLogoRedImg,
  jumeirahbeachImg,
  landingfigImg,
  languagebannerImg,
  LanguageIconImg,
  laundryImg,
  lightImg,
  locationImg,
  lostandfoundImg,
  lostandfoundbannerImg,
  lostitemImg,
  logoutImg,
  maintanancebannerImg,
  maintananceImg,
  maintenanceImg,
  mapImg,
  menuitemImg,
  minibarImg,
  moviebookingImg,
  myDoorImg,
  msgiconImg,
  musicnightbannerImg,
  musicnightsImg,
  myhoteliconImg,
  notificationImg,
  overallfeedbackBannerImg,
  overallfeedbackImg,
  pdficon,
  picklaundrybannerImg,
  pillowsImg,
  powerdlogoImg,
  powerdtextImg,
  privacypolicyiconImg,
  profileiconImg,
  profileImg,
  promotionBanner,
  promotionBG1,
  promotionsImg,
  qrImg,
  ratingImg,
  refrgeratorImg,
  replacementbannerImg,
  replacementsImg,
  requestbillImg,
  restaurantbannerImg,
  restaurantImg,
  RestaurantPlaceholderImg,
  roomcleaningbannerImg,
  roomcleaningImg,
  roomservicebannerImg,
  roomserviceImg,
  serviceRatingHeaderImg,
  skydubaiImg,
  spaBanner,
  spaImg,
  spawellness_salon,
  spawellnessbanner,
  spawellnessImg,
  successImg,
  successtick,
  sunsetsImg,
  sw_gym,
  sw_gymbanner,
  sw_saloonbanner,
  telivisionImg,
  termsbannerImg,
  termscondtionsiconImg,
  timeImg,
  toiletriesImg,
  traveldeskbannerImg,
  traveldeskfigImg,
  traveldeskImg,
  viewbillImg,
  viewmybillbannerImg,
  wakeupcallbannerImg,
  wakeupcallImg,
  waterleakageImg,
  welcomeImg,
  webKeyProviderImg,
  doorImg,
  medicalDoctorImg,
  doctorPatient,
  cartImage,
  dishPlaceHolderImg,
  preCheckinConfirmation,
}

export function getImage(image) {
  return image ? require(`../assets/images/${image}`).default : ''
}
