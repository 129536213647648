import axios from 'axios'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Router } from 'react-router'
import Routes from './routes/index'
import { history } from './history'
import store, { persistor } from './Store'
import AppProviderWrapper from './utility/AppProviderWrapper'
import {
  OnlineStatusContext,
  OnlineStatusProvider,
} from './utility/useOnlineStatus'
import InstallPWA from './InstallPWA'
import { networkStatus } from './constants/general'
import { Loader } from './components/Common/Loader'
import OfflinePage from './components/Pages/OfflinePage'
import BrowserCheck from './components/Common/BrowserCheck/BrowserCheck'
import { useLocation } from 'react-router-dom'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.get['Accept'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'

function App() {
  const renderApp = {
    [networkStatus.SUCCESS]: (
      <AppProviderWrapper>
        <Router history={history}>
        <AppContent />
          <Routes></Routes>
        </Router>
      </AppProviderWrapper>
    ),

    [networkStatus.FAILED]: <OfflinePage />,
  }
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <OnlineStatusProvider>
            <OnlineStatusContext.Consumer>
              {context => (
                  renderApp?.[context.online] ?? <Loader />
              )}
            </OnlineStatusContext.Consumer>
          </OnlineStatusProvider>
        </PersistGate>
      </Provider>
    </>
  )
}

function AppContent() {
  const location = useLocation()
  const isPrecheckin = location.pathname.includes('/prechecking')
  return (
    <>
      {!isPrecheckin && <InstallPWA />}
    </>
  )
}
export default App
